
    .button {
        position: relative;
    }

    .button .button-inner {
        display: flex;
        align-items: center;
        justify-content: center;


        .height-keeper {
            width: 0;
            pointer-events:none;
            overflow:hidden;
            color: transparent
        }
    }

    .button.button--loading .button-inner {
        .button-text, .button__icon, .button__icon-end {
            opacity: 0;
            color: transparent;
            pointer-events: none;
            visibility: hidden;
        }
    }

    .button.button--loading, .button.button--loading[disabled] {
        cursor: wait;
    }

    .button[disabled] {

    }

    .button-text {
        white-space: nowrap;
    }
